<template>
  <ConfirmationModal @closemodal="closeConfirmation" @confirmmodal="confirmed" v-show="needsConfirmation" :action="$t(confirmationType)" />
  <div v-show="!isLoading" class="row">
    <div class="col-md-12">
    <Card title="batches.list.title">
      <template v-slot:default>
        <h2 v-if="!existingBatches" class="text-muted text-center">{{ $t('batches.list.empty')}}</h2>
        <h2 v-else class="text-muted text-center">{{ $t('batches.list.total_existing', {total: existingBatches})}}</h2>
        <div>
          <div class="row">
            <h1 class="col-xl-12">{{$t('batches.list.cycle.name', {name: activeCycle && activeCycle.getName()})}}</h1>
            <div class="col-xl-6">
              <p>{{$t('batches.list.cycle.order_batch', {order_batch_date: activeCycle && formatMomentDate(activeCycle.getOrderBatch())})}}</p>
              <p>{{$t('batches.list.cycle.deliver_order', {deliver_order_date: activeCycle && formatMomentDate(activeCycle.getDeliverOrder())})}}</p>
            </div>
          </div>
          <br class="clearfix" />
          <div class="row">
            <h3 class="col-xl-12"> {{ $t('batches.list.order_plan_notice', {nr: ordersReadyForBatching})}}</h3>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between w-100">
          <Button @click="goToBatchList" type="link-prev" name="batches.buttons.back"></Button>
          <Button v-bind:disabled="!hasOrdersReadyForBatching" @click="askConfirmation('batches.list.confirm.generate')" type="confirm" name="batches.buttons.generate"></Button>
        </div>
      </template>
    </Card>
    </div>
  </div>
</template>

<script>
import Card from "@/components/elements/Card";
import Order from "@/entities/Order";
import Button from "@/components/elements/Button";
import ConfirmationModal from "@/components/elements/ConfirmationModal";
import {LoadingModalMixin, ConfirmationModalMixin} from "@/mixins/ModalMixin";
import {DatesMixin} from "@/mixins/GeneralMixin";

export default {
  name: "BatchGenerate",
  mixins: [LoadingModalMixin, ConfirmationModalMixin, DatesMixin],
  components: {ConfirmationModal, Card, Button},
  props: {
    existingBatches: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      ordersReadyForBatching: 0,
    }
  },
  emits: ['update', 'show'],
  mounted() {
    this.getBatchedOrdersCount();
  },
  computed: {
    hasOrdersReadyForBatching: function() {
      return this.ordersReadyForBatching > 0;
    },
    activeCycle: function () {
      return this.$store.state.cycle;
    }
  },
  methods: {
    onLoadingComplete: function () {
      if (this.initialise) {
        this.$emit('update', false);
        this.getBatchedOrdersCount();
      }

      if (0 === this.ordersReadyForBatching) {
        this.$router.push({name: "general.breadcrumb.batches.list"})
      }
    },
    confirmed()
    {
      this.generateBatches();
      this.closeConfirmation();
    },
    generateBatches() {
      let that = this;
      this.queueJob();
      this.axios.post(this.$store.state.config.getBatchesGenerateUri())
          .catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob(true);
      })
    },
    getBatchedOrdersCount: function () {
      let that = this;
      let filters = {
        status: Order.getReadyForBatchingStatuses(),
        cycle: [this.activeCycle ? this.activeCycle.getId() : 0],
      };

      that.queueJob();
      this.axios.get(this.$store.state.config.getOrdersUri(), {params: filters}).then(
          response => {
            let content = response.data;
            this.ordersReadyForBatching = content.data.total;
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    },
    goToBatchList() {
      this.$emit('update', false);
      this.$router.push({name: 'general.breadcrumb.batches.list'})
    }
  }
}
</script>

<style scoped>

</style>